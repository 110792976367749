





























import Vue from "vue";
import CategoryNav from "@/components/CategoryNav.vue";
import ProductCard from "@/components/ProductCard.vue";
import { Shop } from "@/constants";

export default Vue.extend({
  name: "Shop",
  components: {
    CategoryNav,
    ProductCard,
  },
  data: () => ({
    Shop,
  }),
});
